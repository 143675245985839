import EditUser from './EditUser';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getClient} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user, users, addingUser, addedUser, updatedUser, updatingUser, lastUser} = getUser(state);
  const {clients} = getClient(state);

  return {
    user,
    users,
    addingUser,
    addedUser,
    updatedUser,
    updatingUser, 
    lastUser,
    clients
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditUser);
