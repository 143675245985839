// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  visitorCount: [],
  gettingVisitorCount: false,
  gotVisitorCount: false,
  realTimeCount: [],
  totalVisitsCount: [],
  chosenDestinations: [],
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.GETTING_VISITOR_COUNT:
      return {
        ...state, gettingVisitorCount: true, gotVisitorCount: false
      };
    case actionConstants.GOT_VISITOR_COUNT:
      return {
        ...state, gettingVisitorCount: false, gotVisitorCount: true, visitorCount: action.payload
      };
    case actionConstants.GOT_REAL_TIME_COUNT:
      return {
        ...state, realTimeCount: action.payload
      };
    case actionConstants.GOT_TOTAL_VISITS_COUNT:
      return {
        ...state, totalVisitsCount: action.payload
      };  
    case actionConstants.GOT_CHOSEN_DESTINATIONS:
      return {
        ...state, chosenDestinations: action.payload
      };    
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getDashboard = ({
  gettingVisitorCount, gotVisitorCount, visitorCount, realTimeCount, totalVisitsCount, chosenDestinations,
}) => ({
  gettingVisitorCount,
  gotVisitorCount,
  visitorCount,
  realTimeCount,
  totalVisitsCount,
  chosenDestinations,
});
