// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  loadingClients: false,
  loadedClients: false,
  clients: [],
  addingClient: false,
  addedClient: false,
  updatingClient: false,
  updatedClient: false,
  client: null,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_CLIENTS:
      return {
        ...state, loadingClients: true, loadedClients: false
      };
    case actionConstants.LOADED_CLIENTS:
      return {
        ...state, loadingClients: false, loadedClients: true, clients: action.payload
      };
    case actionConstants.ADDING_CLIENT:
      return {
        ...state, addingClient: true, addedClient: false
      };
    case actionConstants.ADDED_CLIENT:
      return {
        ...state, addingClient: false, addedClient: true, client: action.payload
      };
    case actionConstants.UPDATING_CLIENT:
      return {
        ...state, updatingClient: true, updatedClient: false
      };
    case actionConstants.UPDATED_CLIENT:
      return {
        ...state, updatingClient: false, updatedClient: true, client: action.payload
      };  
    case actionConstants.CLEAR_ADDED_CLIENT:
      return {
        ...state, addingClient: false, addedClient: false, updatingClient: false, updatedClient: false
      };     
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getClient = ({
  loadingClients, loadedClients, clients, addingClient, addedClient, client, updatingClient, updatedClient
}) => ({
  loadingClients,
  loadedClients,
  clients,
  addingClient,
  addedClient,
  client,
  updatingClient,
  updatedClient
});
