import firebase from 'firebase';
import * as actionConstants from '../actionConstants';
const axios = require('axios');

export const getUser = () => async dispatch => {
  dispatch({ type: actionConstants.LOADING_USER });
  let currentUser = await firebase.auth().currentUser;

  return firebase
    .firestore()
    .collection('users')
    .doc(currentUser.uid)
    .get()
    .then(doc => {
      dispatch({ type: actionConstants.LOADED_USER, payload: {id: doc.id, ...doc.data()}})
    })
    .catch(error => console.log(error))

};

export const clearAddedUser = () => dispatch => {
  dispatch({type: actionConstants.CLEAR_ADDED_USER})
}

export const getUsers = () => async (dispatch, getState) => {
  dispatch({ type: actionConstants.LOADING_USERS });
  let currentUser = await firebase.auth().currentUser;

  if (getState().user.user.type === 'super') {
    return firebase
    .firestore()
    .collection('users')
    .get()
    .then(querySnapshot => {
      let users = [];
      querySnapshot.forEach(doc => {
        users.push({id: doc.id, ...doc.data()})
      })
      dispatch({ type: actionConstants.LOADED_USERS, payload: users})
    })
    .catch(error => console.log(error))
  } else {
    return firebase
      .firestore()
      .collection('users')
      .where('client', '==', getState().user.user.client)
      .get()
      .then(querySnapshot => {
        let users = [];
        querySnapshot.forEach(doc => {
          users.push({id: doc.id, ...doc.data()})
        })
        dispatch({ type: actionConstants.LOADED_USERS, payload: users})
      })
      .catch(error => console.log(error))
  }
} 

  

export const getLiveUsers = (userId) => async (dispatch, getState) => {
  dispatch({ type: actionConstants.LOADING_USERS });
  let currentUser = await firebase.auth().currentUser;

  if (getState().user.user.type === 'super') {
    return firebase
    .firestore()
    .collection('users')
    .onSnapshot(querySnapshot => {
      let users = [];
      querySnapshot.forEach(doc => {
        users.push({id: doc.id, ...doc.data()})
      })
      dispatch({ type: actionConstants.LOADED_USERS, payload: users})
    })
  } else {
    return firebase
      .firestore()
      .collection('users')
      .where('client', '==', getState().user.user.client)
      .onSnapshot(querySnapshot => {
        let users = [];
        querySnapshot.forEach(doc => {
          users.push({id: doc.id, ...doc.data()})
        })
        dispatch({ type: actionConstants.LOADED_USERS, payload: users})
      })
  }
};

/**
 * Add a user
 *
 * @param data
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
 export const addUser = (data, callback) => {
  return async (dispatch, getState) => {
    dispatch({type: actionConstants.ADDING_USER});
    const createUserResponse = await axios.post('https://us-central1-waysnap-de599.cloudfunctions.net/createUser', {email: data.email.toLowerCase(), password: data.password})
    if (createUserResponse.status === 200) {
      const uid = createUserResponse.data.uid
      const userData = {
        name: data.name,
        email: data.email.toLowerCase(),
        client: data.client,
        type: data.type,
        active: data.active,
      };
      return firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .set(userData)
        .then(doc => {
          dispatch({
            type: actionConstants.ADDED_USER,
            payload: {id: uid, user: getState().user.user.id, ...userData},
          });
          callback && callback({id: uid, user: getState().user.user.id, ...userData})
        });
      } else {
        callback && callback()
      }
  };
};

/**
 * Edit a user
 *
 * @param data
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
export const editUser = (user, data, callback) => {
  return (dispatch, getState) => {
    dispatch({type: actionConstants.UPDATING_USER});
    const userData = {
      name: data.name,
      email: data.email,
      client: data.client,
      type: data.type,
      active: data.active,
    };
    return firebase
      .firestore()
      .collection('users')
      .doc(user.id)
      .update(userData)
      .then(doc => {
        dispatch({
          type: actionConstants.UPDATED_USER,
          payload: {id: user.id, user: getState().user.user.id, ...userData},
        });
        callback && callback({id: user.id, user: getState().user.user.id, ...userData})
      });
  };
};
