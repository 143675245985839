import React from "react";
import classNames from "classnames";
import { Card, CardBody } from "shards-react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const SmallStats = (props) => {

  const chartOptions = {
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    elements: {
      point: {
        radius: 0
      },
      line: {
        tension: 0.33
      }
    },
    tooltips: {
      enabled: false,
      custom: false
    },
    interaction: {
      mode: 'index',
      intersect: false
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawTicks: false,
        },
        ticks: {
          display: false
        }
      },
      y: {
        grid: {
          display: false,
          drawTicks: false,
        },
        ticks: {
          display: false,
        },
      },
      myScale: {
        display: false
      }
    },
  };

  const { variation, label } = props;

  const getMainFigure = () => {
    if (props.chartData[0].data) {
      return props.chartData[0].data[props.chartData[0].data.length - 1]
    }
  }

  const getPercentageFigure = () => {
    if (props.chartData[0].data) {
      let perc = (props.chartData[0].data[props.chartData[0].data.length - 1] - props.chartData[0].data[props.chartData[0].data.length - 2]) /  props.chartData[0].data[props.chartData[0].data.length - 2] * 100
      if (props.chartData[0].data[props.chartData[0].data.length - 2] === 0 || props.chartData[0].data[props.chartData[0].data.length - 1] === 0) {
        return 100
      }
      return parseFloat(perc).toFixed(0)
    }
  }

  const isIncrease = () => {
    if (props.chartData[0].data) {
      return props.chartData[0].data[props.chartData[0].data.length - 1] > props.chartData[0].data[props.chartData[0].data.length - 2]
    }
    
  }

  const isDecrease = () => {
    return props.chartData[0].data[props.chartData[0].data.length - 1] < props.chartData[0].data[props.chartData[0].data.length - 2]
  }

  const cardClasses = classNames(
    "stats-small",
    variation && `stats-small--${variation}`
  );

  const cardBodyClasses = classNames(
    variation === "1" ? "p-0 d-flex" : "px-0 pb-0"
  );

  const innerWrapperClasses = classNames(
    "d-flex",
    variation === "1" ? "flex-column m-auto" : "px-3"
  );

  const dataFieldClasses = classNames(
    "stats-small__data",
    variation === "1" && "text-center"
  );

  const labelClasses = classNames(
    "stats-small__label",
    "text-uppercase",
    variation !== "1" && "mb-1"
  );

  const valueClasses = classNames(
    "stats-small__value",
    "count",
    variation === "1" ? "my-3" : "m-0"
  );

  const innerDataFieldClasses = classNames(
    "stats-small__data",
    variation !== "1" && "text-right align-items-center"
  );

  const percentageClasses = classNames(
    "stats-small__percentage",
    `stats-small__percentage--${isIncrease() ? "increase" : "decrease"}`
  );

  const canvasHeight = 60;

  return (
    <Card small className={cardClasses}>
      <CardBody className={cardBodyClasses}>
        <div className={innerWrapperClasses}>
          <div className={dataFieldClasses}>
            <span className={labelClasses}>{label}</span>
            <h6 className={valueClasses}>{getMainFigure()}</h6>
          </div>
          <div className={innerDataFieldClasses}>
            <span className={percentageClasses}>{getPercentageFigure() + '%'}</span>
          </div>
        </div>
        <Chart
          type='line'
          datasetIdKey='1'
          options={chartOptions}
          data={{
            labels: props.chartLabels,
            datasets: props.chartData,
          }}
          height={canvasHeight}
          maxHeight={canvasHeight}
        />
      </CardBody>
    </Card>
  );
}

export default SmallStats;
