// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  loadingLocations: false,
  loadedLocations: false,
  locations: [],
  addingLocation: false,
  addedLocation: false,
  updatingLocation: false,
  updatedLocation: false,
  location: null,
  loadingWaypoints: false,
  loadedWaypoints: false,
  waypoints: [],
  locationWaypoints: [],
  generatingWaypoints: false,
  generatedWaypoints: true,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_LOCATIONS:
      return {
        ...state, loadingLocations: true, loadedLocations: false
      };
    case actionConstants.LOADED_LOCATIONS:
      return {
        ...state, loadingLocations: false, loadedLocations: true, locations: action.payload
      };
    case actionConstants.ADDING_LOCATION:
      return {
        ...state, addingLocation: true, addedLocation: false
      };
    case actionConstants.ADDED_LOCATION:
      return {
        ...state, addingLocation: false, addedLocation: true, location: action.payload
      };
    case actionConstants.UPDATING_LOCATION:
      return {
        ...state, updatingLocation: true, updatedLocation: false
      };
    case actionConstants.UPDATED_LOCATION:
      return {
        ...state, updatingLocation: false, updatedLocation: true, location: action.payload
      };  
    case actionConstants.CLEAR_ADDED_LOCATION:
      return {
        ...state, addingLocation: false, addedLocation: false, updatingLocation: false, updatedLocation: false
      };
    case actionConstants.LOADING_WAYPOINTS:
      return {
        ...state, loadingWaypoints: true, loadedWaypoints: false
      };
    case actionConstants.LOADED_WAYPOINTS:
      return {
        ...state, loadingWaypoints: false, loadedWaypoints: true, waypoints: action.payload
      };
    case actionConstants.CLEAR_LOCATION_STATE:
      return {
        ...state, loadingLocations: false, loadedLocations: false, locations: [], waypoints: []
      };
    case actionConstants.LOADING_LIVE_WAYPOINTS:
      return {
        ...state, locationWaypoints: state.locationWaypoints
      };  
    case actionConstants.LOADED_LIVE_WAYPOINTS:
      return {
        ...state, locationWaypoints: action.payload
      };  
    case actionConstants.GENERATING_WAYPOINTS:
      return {
        ...state, generatingWaypoints: true, generatedWaypoints: false
      };   
    case actionConstants.GENERATED_WAYPOINTS:
      return {
        ...state, generatingWaypoints: false, generatedWaypoints: true
      };  
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getLocation = ({
  loadingLocations, loadedLocations, locations, addingLocation, addedLocation, location, updatingLocation, updatedLocation, loadingWaypoints, loadedWaypoints, waypoints, locationWaypoints, generatingWaypoints, generatedWaypoints
}) => ({
  loadingLocations,
  loadedLocations,
  locations,
  addingLocation,
  addedLocation,
  location,
  updatingLocation,
  updatedLocation,
  loadingWaypoints,
  loadedWaypoints,
  waypoints,
  locationWaypoints,
  generatingWaypoints,
  generatedWaypoints
});
