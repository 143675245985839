import React from "react";
import {useSelector} from 'react-redux';
import firebase from "firebase";
import { Container } from "shards-react";

import {getUser} from '../reducers/rootReducer';

const BlankLayout = ({ children, noNavbar, noFooter }) => {
  firebase.auth().onAuthStateChanged(user => {
    if (!user) {
      window.location.href = '/login';
    }
  });
  const user = useSelector(state => getUser(state).user);
  return (
    <Container fluid>
      {children}
    </Container>
  )
};

export default BlankLayout;
