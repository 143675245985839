import Clients from './Clients';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getClient} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user} = getUser(state);
  const {clients, client, addedClient, addingClient, updatingClient, updatedClient} = getClient(state);

  return {
    user,
    clients,
    client,
    addedClient,
    addingClient,
    updatingClient,
    updatedClient
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Clients);
