// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  loggingIn: false,
  loggedIn: false,
  loginFail: false,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOGGING_IN:
      return {
        ...state, loggingIn: true, loggedIn: false, loginFail: false,
      };
    case actionConstants.LOGIN_FAIL:
      return {
        ...state, loggingIn: false, loggedIn: false, loginFail: true,
      };
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getLogin = ({loggingIn, loggedIn, loginFail}) => ({
  loggingIn,
  loggedIn,
  loginFail
});
