import EditLocation from './EditLocation';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getLocation, getClient} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user} = getUser(state);
  const {clients} = getClient(state);
  const {addingLocation, addedLocation, locations, location, loadedLocations, loadingLocations} = getLocation(state);

  return {
    user,
    clients,
    addingLocation,
    addedLocation,
    locations,
    location,
    loadedLocations,
    loadingLocations,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditLocation);
