import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, CardHeader, Card, CardBody, FormSelect } from "shards-react";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { accessCheck } from "../../utils/tools";

import PageTitle from "../../components/common/PageTitle";
import { getChosenRoutes, getVisitors } from "../../actions/dashboardActions";
import SmallStats from "../../components/common/SmallStats";

const moment = require('moment');

const Dashboard = (props) => {

  const [visitorCount, setVisitorCount] = useState([])
  const [realTimeCount, setRealTimeCount] = useState([])
  const [totalVisitsCount, setTotalVisitsCount] = useState([])
  const [chosenDestinations, setChosenDestinations] = useState([])
  const [chosenStartPoints, setChosenStartPoints] = useState([])
  const [selectedMonth, setSelectedMonth] = useState(moment().format('MM'))
  const [location, setLocation] = useState('all')
  const [locations, setLocations] = useState(props.locations)
  const [client, setClient] = useState(props.user.type === 'super' ? 'all' : props.clients && props.clients.length > 0 ? props.clients[0].id : null)

  const chartOptions = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      myScale: {
        position: 'right'
      }
    },
    
  }

  useEffect(() => {
    document.title = 'Dashboard: WaySnap';
    props.getWaypoints()
    !accessCheck(props.user, 'dashboard') && (window.location.href = '/') 
    console.log(selectedMonth)
  }, [])

  useEffect(() => {
    if (location && client && selectedMonth) {
      let { startDate, endDate } = getStartAndEndDate()
      props.getVisitorCounts(location !== 'all' ? location : null, client !== 'all' ? client : null, startDate.toISOString(), endDate.toISOString())
      props.getRealTimeCount(location !== 'all' ? location : null, client !== 'all' ? client : null, startDate.toISOString(), endDate.toISOString())
      props.getTotalVisitsCount(location !== 'all' ? location : null, client !== 'all' ? client : null, startDate.toISOString(), endDate.toISOString())
      props.getChosenRoutes(location !== 'all' ? location : null, client !== 'all' ? client : null, startDate.toISOString(), endDate.toISOString())
      if (client !== 'all' && props.locations && props.locations > 0) {
        setLocations(props.locations.filter(location => location.client === client))
      } else {
        setLocations(props.locations)
      }
    }
    
  }, [location, client, selectedMonth])

  useEffect(() => {
    setVisitorCount(props.visitorCount)
  }, [props.visitorCount])

  useEffect(() => {
    setRealTimeCount(props.realTimeCount)
  }, [props.realTimeCount])

  useEffect(() => {
    setTotalVisitsCount(props.totalVisitsCount)
  }, [props.totalVisitsCount])

  useEffect(() => {
    setChosenDestinations(calculateMostVisitedDestinations(props.chosenDestinations, props.locations, props.clients))
    setChosenStartPoints(calculateMostVisitedStartPoints(props.chosenDestinations, props.locations, props.clients))
  }, [props.chosenDestinations, props.loadedWaypoints])

  const getStartAndEndDate = () => {
    let startDate = moment(moment().format('Y') + '-' + selectedMonth + '-01 00:00:00').startOf('month')
    let endDate = moment(moment().format('Y') + '-' + selectedMonth + '-01 00:00:00').endOf('month')
    return { startDate, endDate } 
  }

  const getDateLabels = () => {
    let { startDate, endDate } = getStartAndEndDate()
    let start = 1
    let end = endDate.diff(startDate, 'days') + 1
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  }

  const calculateMostVisitedDestinations = (routes, locations, clients) => {
    let mostVisited = {};
    let result = [];
    if (routes && routes.length > 0 && props.waypoints && props.waypoints.length > 0) {
      routes.map(route => {
        if (Object.keys(mostVisited).indexOf(route.to) !== -1) {
          mostVisited[route.to]['count'] = mostVisited[route.to]['count'] + 1
        } else {
          mostVisited[route.to] = {}
          mostVisited[route.to]['count'] = 1;
          mostVisited[route.to]['client'] = route.client;
          mostVisited[route.to]['location'] = route.location;
        }
      })
      result = Object.keys(mostVisited).map(key => ({
        name: props.waypoints.find(waypoint => waypoint.id === key) ? props.waypoints.find(waypoint => waypoint.id === key).name : null,
        location: locations.find(location => location.id === mostVisited[key]['location']) && locations.find(location => location.id === mostVisited[key]['location']).name,
        client: clients.find(client => client.id === mostVisited[key]['client']) && clients.find(client => client.id === mostVisited[key]['client']).name,
        count: mostVisited[key]['count']
      }))
      result = result.sort((a, b) => b.count - a.count)
    }
    return result
  }

  const calculateMostVisitedStartPoints = (routes, locations, clients) => {
    let mostVisited = {};
    let result = [];
    if (routes && routes.length > 0 && props.waypoints && props.waypoints.length > 0) {
      routes.map(route => {
        if (Object.keys(mostVisited).indexOf(route.from) !== -1) {
          mostVisited[route.from]['count'] = mostVisited[route.from]['count'] + 1
        } else {
          mostVisited[route.from] = {}
          mostVisited[route.from]['count'] = 1;
          mostVisited[route.from]['client'] = route.client;
          mostVisited[route.from]['location'] = route.location;
        }
      })
      result = Object.keys(mostVisited).map(key => ({
        name: props.waypoints.find(waypoint => waypoint.id === key) ? props.waypoints.find(waypoint => waypoint.id === key).name : null,
        location: locations.find(location => location.id === mostVisited[key]['location']).name,
        client: clients.find(client => client.id === mostVisited[key]['client']).name,
        count: mostVisited[key]['count']
      }))
      result = result.sort((a, b) => b.count - a.count)
    }
    return result
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle title="Dashboard" className="text-sm-left mb-3" />
      </Row>

     {(!props.clients || props.clients.length === 0 || !props.locations || !props.users || !props.waypoints) ? (
       <Row>
        <Col className="mb-4">Loading...</Col>
      </Row>  
     ) : (
       <>
        <Row>
          <Col className="mb-4">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs="12" sm="4">
                    <h6 className="m-0">Visitors</h6>
                  </Col>
                  <Col xs="12" sm="8" className='text-right'>
                    <Row>
                      <Col xs="1"></Col>
                      <Col className='text-right pl-0 pr-1'>
                        <FormSelect value={selectedMonth} id="feMonth" onChange={
                          event => { 
                            setSelectedMonth(event.target.value);
                          }}>
                          <option value={'01'}>January</option>
                          <option value={'02'}>February</option>
                          <option value={'03'}>March</option>
                          <option value={'04'}>April</option>
                          <option value={'05'}>May</option>
                          <option value={'06'}>June</option>
                          <option value={'07'}>July</option>
                          <option value={'08'}>August</option>
                          <option value={'09'}>September</option>
                          <option value={'10'}>October</option>
                          <option value={'11'}>November</option>
                          <option value={'12'}>December</option>
                        </FormSelect>
                      </Col>
                      <Col className='text-right pl-0 pr-1'>
                        {props.user.type === 'super' && (
                          <FormSelect value={client} id="feClients" onChange={event => setClient(event.target.value)}>
                            <option value={'all'}>All clients</option>
                            {props.clients.map(client => (
                              <option value={client.id}>{client.name}</option>
                            ))}
                          </FormSelect>
                        )}
                      </Col>
                      <Col className='text-right pl-0'>
                        <FormSelect value={location} id="feLocations" onChange={event => setLocation(event.target.value)}>
                          <option value={'all'}>All locations</option>
                          {locations && locations.map(location => (
                            <option value={location.id}>{location.name}</option>
                          ))}
                        </FormSelect>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Chart
                  type='bar'
                  datasetIdKey='id'
                  options={chartOptions}
                  data={{
                    labels: getDateLabels(),
                    datasets: [
                      {
                        id: 1,
                        label: '',
                        data: visitorCount,
                        backgroundColor: '#2F65D8',
                        gridLines: false,
                      },
                    ],
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="col-lg mb-4" md="6" sm="6">
            <SmallStats
              id={`small-stats-0`}
              variation="1"
              chartData={
                [
                  {
                    id: 1,
                    fill: true,
                    borderWidth: 1.5,
                    backgroundColor: "rgba(0, 184, 216, 0.1)",
                    borderColor: "rgb(0, 184, 216)",
                    data: realTimeCount
                  }
                ]
              }
              chartLabels={Array.from(Array(12).keys()).map(key => 12 - (key + 1)).map(num => moment().subtract(num * 15, 'minutes').format("h:mm a"))}
              label={"Realtime users"}
            />
          </Col>
          <Col className="col-lg mb-4" md="6" sm="6">
            <SmallStats
              id={`small-stats-1`}
              variation="1"
              chartData={
                [
                  {
                    id: 2,
                    fill: true,
                    borderWidth: 1.5,
                    backgroundColor: "rgba(23,198,113,0.1)",
                    borderColor: "rgb(23,198,113)",
                    data: totalVisitsCount
                  }
                ]
              }
              chartLabels={Array.from(Array(12).keys()).map(key => 12 - (key + 1)).map(num => moment().subtract(num, 'months').format("MMM"))}
              label={"Total visits"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col xs="12" sm="4">
                    <h6 className="m-0">Most visited destinations</h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        Destination name
                      </th>
                      <th scope="col" className="border-0">
                        Location
                      </th>
                      {props.user.type === 'super' && (
                        <th scope="col" className="border-0">
                          Client
                        </th>
                      )}
                      <th scope="col" className="border-0">
                        Visits
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {chosenDestinations.length > 0 && chosenDestinations.map(destination => {
                      return (
                        <tr key={destination.name}>
                          <td>
                            {destination.name}
                          </td>
                          <td>
                            {destination.location}
                          </td>
                          {props.user.type === 'super' && (
                            <td>
                              {destination.client}
                            </td>
                          )}
                          <td>
                            {destination.count}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col xs="12" sm="4">
                    <h6 className="m-0">Most visited start point</h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        Start point name
                      </th>
                      <th scope="col" className="border-0">
                        Location
                      </th>
                      {props.user.type === 'super' && (
                        <th scope="col" className="border-0">
                          Client
                        </th>
                      )}
                      <th scope="col" className="border-0">
                        Visits
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {chosenStartPoints.length > 0 && chosenStartPoints.map(startPoint => {
                      return (
                        <tr key={startPoint.name}>
                          <td>
                            {startPoint.name}
                          </td>
                          <td>
                            {startPoint.location}
                          </td>
                          {props.user.type === 'super' && (
                            <td>
                              {startPoint.client}
                            </td>
                          )}
                          <td>
                            {startPoint.count}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
       </>
     )} 
    </Container>
  )
};

export default Dashboard;
