import QRCodes from './QRCodes';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getClient, getLocation} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user} = getUser(state);
  const {clients, client} = getClient(state);
  const {locations, location, locationWaypoints} = getLocation(state);

  return {
    user,
    clients,
    client,
    locations,
    location,
    locationWaypoints,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QRCodes);
