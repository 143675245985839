import Dashboard from './Dashboard';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getClient, getDashboard, getLocation} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user, users} = getUser(state);
  const {clients} = getClient(state);
  const {visitorCount, realTimeCount, totalVisitsCount, chosenDestinations} = getDashboard(state)
  const {locations, waypoints, loadedWaypoints} = getLocation(state);

  return {
    user,
    users,
    clients,
    locations,
    visitorCount,
    realTimeCount,
    totalVisitsCount,
    chosenDestinations,
    waypoints,
    loadedWaypoints,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
