import firebase from 'firebase';
import * as actionConstants from '../actionConstants';
const algoliasearch = require('algoliasearch')
const client = algoliasearch('YLXZEVKXMN', '2a7ec9108c48129150285007f1eb8eee')
const chosenRoutesIndex = client.initIndex('chosenRoutes')
const visitsIndex = client.initIndex('visits')

const moment = require('moment');

export const getRealTimeCount = (location, client) => async dispatch => {
  let startTime = moment().subtract(3, 'hours')
  let endTime = moment()
  let dailyCount = []
  let end = moment(startTime);
  // If you want an inclusive end date (fully-closed interval)
  let visits = await getVisitorCountByDate(location, client, startTime.toDate(), moment().toDate()).then(data => data)
  for (let m = moment(startTime); m.diff(endTime, 'minutes') < 0; m.add(15, 'minutes')) {
    end.add(15, 'minutes')
    let count = visits.filter(visit => visit.timeStamp_unix > m.unix() && visit.timeStamp_unix < end.unix()).length
    dailyCount.push(count)
  }
  //let result = await Promise.all(dailyCount).then(data => data)
  dispatch({ type: actionConstants.GOT_REAL_TIME_COUNT, payload: dailyCount });
};

export const getTotalVisitsCount = (location, client, startDate, endDate) => async dispatch => {
  startDate = moment(startDate).subtract(11, 'months')
  let dailyCount = []
  let end = moment(startDate);
  // If you want an inclusive end date (fully-closed interval)
  let visits = await getVisitorCountByDate(location, client, startDate.toDate(), moment().toDate()).then(data => data)
  for (let m = moment(startDate); m.diff(endDate, 'months') <= 0; m.add(1, 'month')) {
    end.add(1, 'month')
    let count = visits.filter(visit => visit.timeStamp_unix > m.unix() && visit.timeStamp_unix < end.unix()).length
    dailyCount.push(count)
  }
  // let result = await Promise.all(dailyCount).then(data => data)
  dispatch({ type: actionConstants.GOT_TOTAL_VISITS_COUNT, payload: dailyCount.slice(0, 12) });
};

export const getVisitorCounts = (location, client, startDate, endDate) => async dispatch => {
  dispatch({ type: actionConstants.GETTING_VISITOR_COUNT });
  let dailyCount = []
  // If you want an inclusive end date (fully-closed interval)
  let visits = await getVisitorCountByDate(location, client, moment(startDate).startOf('day').toDate(), moment(endDate).endOf('day').toDate()).then(data => data)
  for (let m = moment(startDate); m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
    let count = visits.filter(visit => visit.timeStamp_unix > m.startOf('day').unix() && visit.timeStamp_unix < m.endOf('day').unix()).length
    dailyCount.push(count)
  }
  //let result = await Promise.all(dailyCount).then(data => data)
  dispatch({ type: actionConstants.GOT_VISITOR_COUNT, payload: dailyCount });
};

export const getChosenRoutes = (location, client, startDate, endDate) => async (dispatch, getState) => {
  if (location) {
    client = getState().location.locations.find(locationRef => locationRef.id === location).client
  }
  let result = await getChosenRoutesByDate(location, client, startDate, endDate).then(data => data)
  dispatch({ type: actionConstants.GOT_CHOSEN_DESTINATIONS, payload: result });
};

const getVisitorCountByDate = async (location, client, startDate, endDate) => {

  startDate = moment.utc(startDate).unix()
  endDate = moment.utc(endDate).unix()

  let filter = 'timeStamp_unix > ' + startDate + ' AND timeStamp_unix < ' + endDate

  if (location) {
    filter = filter + ' AND location:"' + location + '"'
  }

  if (client) {
    filter = filter + ' AND client:"' + client + '"'
  }

  let count = await visitsIndex.search('', {
    filters: filter
  }).then(hits => {
    return hits.hits
  });
  return count;
  
  // if (location) {
  //   return firebase
  //     .firestore()
  //     .collection('visits')
  //     .where('location', '==', location)
  //     .where('timestamp', '>', startDate)
  //     .where('timestamp', '<', endDate)
  //     .get()
  //     .then(querySnapshot => {
  //       return returnCounts(querySnapshot)
  //     })
  // }

  // if (client && !location) {
  //   return firebase
  //     .firestore()
  //     .collection('visits')
  //     .where('client', '==', client)
  //     .where('timestamp', '>', startDate)
  //     .where('timestamp', '<', endDate)
  //     .get()
  //     .then(querySnapshot => {
  //       return returnCounts(querySnapshot)
  //     })
  // }

  // if (!client && !location) {
  //   return firebase
  //     .firestore()
  //     .collection('visits')
  //     .where('timestamp', '>', startDate)
  //     .where('timestamp', '<', endDate)
  //     .get()
  //     .then(querySnapshot => {
  //       return returnCounts(querySnapshot)
  //     })
  //     .catch(error => console.log(error))
  // }

};

const returnCounts = querySnapshot => {
  return querySnapshot.size
}

const getChosenRoutesByDate = async (location, client, startDate, endDate) => {

  startDate = moment.utc(startDate).unix()
  endDate = moment.utc(endDate).unix()

  let filter = 'timeStamp > ' + startDate + ' AND timeStamp < ' + endDate

  if (location) {
    filter = filter + ' AND location:' + location
  }

  if (client) {
    filter = filter + ' AND client:' + client
  }

  let chosenRoutes = await chosenRoutesIndex.search('', {
    filters: filter
  }).then(hits => {
    return hits.hits
  });

  return chosenRoutes;

  // return firebase
  //   .firestore()
  //   .collectionGroup('chosenRoutes')
  //   .where('time', '>', startDate)
  //   .where('time', '<', endDate)
  //   .get()
  //   .then(querySnapshot => {
  //     let chosenRoutes = [];
  //     querySnapshot.forEach(doc => {
  //       let thisLocation = doc.ref.parent.parent.id
  //       let thisClient = doc.ref.parent.parent.parent.parent.id
  //       let add = true;
  //       if (location) {
  //         if (location !== thisLocation) {
  //           add = false;
  //         }
  //       }
  //       if (client) {
  //         if (client !== thisClient) {
  //           add = false;
  //         }
  //       }
  //       if (add) {
  //         chosenRoutes.push({id: doc.id, location: thisLocation, client: thisClient, ...doc.data()})
  //       }
        
  //     })
  //     return chosenRoutes;
  //   })

};