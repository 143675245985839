/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import {
  Alert,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormSelect,
} from "shards-react";
import { accessCheck } from "../../utils/tools";
import PageTitle from "../../components/common/PageTitle";
import { getClient } from "../../reducers/clientReducer";

const Users = (props) => {

  const [users, setUsers] = useState([]);
  const [addedUser, setAddedUser] = useState(props.addedUser);
  const [updatedUser, setUpdatedUser] = useState(props.updatedUser);
  

  useEffect(() => {
    props.getLiveUsers(props.user.id)
    document.title = 'Users: WaySnap admin';
    !accessCheck(props.user, 'users') && (window.location.href = '/')
  }, [])

  useEffect(() => {
    setUsers(props.users)
  }, [props.users])

  useEffect(() => {
    if (props.addedUser) {
      setAddedUser(true);
      setTimeout(() => {
        setAddedUser(false)
        props.clearAddedUser()
      }, 10000);
    }
    if (props.updatedUser) {
      setUpdatedUser(true);
      setTimeout(() => {
        setUpdatedUser(false)
        props.clearAddedUser()
      }, 10000);
    }
  }, [props.addedUser, props.updatedUser])

  const filterUsers = () => {
    let sortedUsers = users;
    
    sortedUsers = sortedUsers.sort((a, b) => a.name && b.name && a.name.localeCompare(b.name));
    return sortedUsers
  }

  const getRole = user => {
    switch (user.type) {
      case "super":
        return 'Super Admin'
      case "admin":
        return 'Admin' 
      case "navigation":
        return 'Navigation specialist'    
    }
  }

  const getClient = user => {
    let client = props.clients.find(client => client.id === user.client)
    if (client) {
      return client.name
    }  else {
      return 'Internal'
    }
  }

  return (
    <Container fluid className="main-content-container px-0">
      {addedUser && (
        <Alert className="mb-0" theme={'success'}>
          <i className="fa fa-info mx-2"></i> {props.lastUser.name} has been succesfully added!
        </Alert>
      )}
      {updatedUser && (
        <Alert className="mb-0" theme={'success'}>
          <i className="fa fa-info mx-2"></i> {props.lastUser.name} has been succesfully updated!
        </Alert>
      )}
      <Container fluid className="main-content-container px-4">

        {/* First Row of Posts */}
        <Row>
          <Col>
            <Card small className="mb-4 mt-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col xs="12" sm="4">
                    <h6 className="m-0">Users</h6>
                  </Col>
                  <Col xs="12" sm="8" className='text-right'>
                    <Button
                      theme="accent"
                      onClick={() => window.location.href='/add-user'}
                    >Add a user</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        Name
                      </th>
                      <th scope="col" className="border-0">
                        Email
                      </th>
                      <th scope="col" className="border-0">
                        Role
                      </th>
                      {props.user.type === 'super' && (
                        <th scope="col" className="border-0">
                          Client
                        </th>
                      )}
                      <th scope="col" className="border-0">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterUsers().length > 0 && filterUsers().map(user => {
                      return (
                        <tr key={user.id}>
                          <td>
                            {user.name}
                          </td>
                          <td>
                            {user.email}
                          </td>
                          <td>
                            {getRole(user)}
                          </td>
                          {props.user.type === 'super' && (
                            <td>
                              {getClient(user)}
                            </td>
                          )}
                          <td className="text-right">
                            <a href={"/edit-user/" + user.id}>Edit</a>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Users;
