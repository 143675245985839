/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormSelect,
} from "shards-react";
import { accessCheck } from "../../utils/tools";

import PageTitle from "../../components/common/PageTitle";

const Locations = (props) => {

  const [locations, setLocations] = useState([]);
  const [status, setStatus] = useState('all');
  const [client, setClient] = useState('all');
  const [addedLocation, setAddedLocation] = useState(props.addedLocation);
  const [updatedLocation, setUpdatedLocation] = useState(props.updatedLocation);
  

  useEffect(() => {
    props.getLiveLocations(props.user.id)
    document.title = 'Locations: WaySnap admin';
    !accessCheck(props.user, 'locations') && (window.location.href = '/') 
  }, [])

  useEffect(() => {
    setLocations(props.locations)
  }, [props.locations])

  useEffect(() => {
    if (props.addedLocation) {
      setAddedLocation(true);
      setTimeout(() => {
        setAddedLocation(false)
        props.clearAddedLocation()
      }, 10000);
    }
    if (props.updatedLocation) {
      setUpdatedLocation(true);
      setTimeout(() => {
        setUpdatedLocation(false)
        props.clearAddedLocation()
      }, 10000);
    }
  }, [props.addedLocation, props.updatedLocation])

  const filterLocations = () => {
    let sortedLocations = locations;
    if (status !== 'all') {
      if (status === 'active') {
        sortedLocations = sortedLocations.filter(location => location.active)
      } else {
        sortedLocations = sortedLocations.filter(location => !location.active)
      }
    }
    if (client !== 'all') {
      sortedLocations = sortedLocations.filter(location => location.client === client)
    }
    sortedLocations = sortedLocations.sort((a, b) => a.name && b.name && a.name.localeCompare(b.name));
    return sortedLocations
  }

  return (
    <Container fluid className="main-content-container px-0">
      {addedLocation && (
        <Alert className="mb-0" theme={'success'}>
          <i className="fa fa-info mx-2"></i> {props.location.name} has been succesfully added!
        </Alert>
      )}
      {updatedLocation && (
        <Alert className="mb-0" theme={'success'}>
          <i className="fa fa-info mx-2"></i> {props.location.name} has been succesfully updated!
        </Alert>
      )}
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}

        {/* First Row of Posts */}
        <Row>
          <Col>
            <Card small className="mb-4 mt-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col xs="12" sm="4">
                    <h6 className="m-0">Locations</h6>
                  </Col>
                  <Col xs="12" sm="8" className='text-right'>
                    <Row>
                      <Col xs="1"></Col>
                      <Col className='text-right pl-0 pr-1'>
                        {props.user.type === 'super' && (
                          <FormSelect id="feClients" onChange={event => setClient(event.target.value)}>
                            <option value={'all'}>All clients</option>
                            {props.clients.map(client => (
                              <option value={client.id}>{client.name}</option>
                            ))}
                          </FormSelect>
                        )}
                      </Col>
                      <Col className='text-right pl-0 pr-1'>
                        <FormSelect id="feInputState" onChange={event => setStatus(event.target.value)}>
                          <option value={'all'}>All statuses</option>
                          <option value={'active'}>Active</option>
                          <option value={'inactive'}>Inactive</option>
                        </FormSelect>
                      </Col>
                      <Col className='text-right pl-0' xs="4">
                        <Button
                          theme="accent"
                          onClick={() => window.location.href='/add-location'}
                        >Add a location</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        Name
                      </th>
                      <th scope="col" className="border-0">
                        Status
                      </th>
                      {props.user.type === 'super' && (
                        <th scope="col" className="border-0">
                          Client
                        </th>
                      )}
                      <th scope="col" className="border-0">
                        Nodes
                      </th>
                      <th scope="col" className="border-0">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterLocations().length > 0 && filterLocations().map(location => {
                      return (
                        <tr key={location.id}>
                          <td>
                            {location.name}
                          </td>
                          <td>
                            {location.active ? 'Active' : 'Inactive'}
                          </td>
                          {props.user.type === 'super' && (
                            <td>
                              {props.clients.find(client => client.id === location.client).name}
                            </td>
                          )}
                          <td>
                            <a href={"/waypoints/" + location.id}>View Nodes</a>
                          </td>
                          <td className="text-right">
                            <a href={"/edit-location/" + location.id}>Edit</a>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Locations;
