// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  user: {},
  users: [],
  lastUser: {},
  loadingUser: false,
  loadedUser: false,
  loadingUsers: false,
  loadedUsers: false,
  updatingUser: false,
  updatedUser: false,
  addingUser: false,
  addedUser: false,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_USER:
      return {
        ...state, loadingUser: true, loadedUser: false, user: {}
      };
    case actionConstants.LOADED_USER:
      return {
        ...state, loadingUser: false, loadedUser: true, user: action.payload
      };
    case actionConstants.LOADING_USERS:
      return {
        ...state, loadingUsers: true, loadedUsers: false, users: []
      };
    case actionConstants.LOADED_USERS:
      return {
        ...state, loadingUsers: false, loadedUsers: true, users: action.payload
      }; 
    case actionConstants.ADDING_USER:
      return {
        ...state, addingUser: true, addedUser: false, lastUser: {}
      };
    case actionConstants.ADDED_USER:
      return {
        ...state, addingUser: false, addedUser: true, lastUser: action.payload
      };
    case actionConstants.UPDATING_USER:
      return {
        ...state, updatingUser: true, updatedUser: false, lastUser: {}
      };
    case actionConstants.UPDATED_USER:
      return {
        ...state, updatingUser: false, updatedUser: true, lastUser: action.payload
      };
    case actionConstants.CLEAR_ADDED_USER:
      return {
        ...state, addingUser: false, addedUser: false, updatingUser: false, updatedUser: false
      };   
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getUser = ({
  user, loadingUser, loadedUser, loadingUsers, loadedUsers, addingUser, addedUser, updatingUser, updatedUser, users, lastUser 
}) => ({
  user,
  loadingUser,
  loadedUser,
  loadingUsers,
  loadedUsers,
  addingUser,
  addedUser,
  updatingUser,
  updatedUser,
  users,
  lastUser 
});
