/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormSelect,
} from "shards-react";
import QRCode from "react-qr-code";
import { accessCheck } from "../../utils/tools";
import { WEB_NAV_URL } from "../../environmentVariables";

import PageTitle from "../../components/common/PageTitle";

const QRCodes = (props) => {

  let {locationId} = useParams();
  const [waypoints, setWaypoints] = useState([]);
  const [location] = useState(props.locations.find(location => location.id === locationId))

  useEffect(() => {
    props.getLiveWaypoints(location)
    document.title = 'QRCodes: WaySnap admin';
    !accessCheck(props.user, 'waypoints') && (window.location.href = '/') 
  }, [])

  useEffect(() => {
    setWaypoints(props.locationWaypoints)
  }, [props.locationWaypoints])

  return (
    <Container fluid className="main-content-container px-4">
      <Row>
        {waypoints && waypoints.length > 0 && waypoints.map((waypoint, idx) => {
          return (
            <Col lg="2" md="4" sm="6" className="mb-4 text-center" key={idx}>
              <QRCode
                size={128}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={'https://client.' + props.clients.find(client => client.id === location.client).domain + '/' + location.locationCode + '/' + waypoint.waypointCode}
                viewBox={`0 0 128 128`}
              />
              <span style={{textAlign: 'center', fontSize: 16}}>{waypoint.waypointCode}</span><br />
              {waypoint.name && waypoint.name.length > 0 && (
                <span style={{textAlign: 'center', fontSize: 16}}>{waypoint.name}</span>
              )}
            </Col>
          )
        })}
      </Row>
    </Container>
  );
}

export default QRCodes;
