import React from "react";
import { Navigate } from "react-router-dom";
import firebase from 'firebase';

// Layout Types
import { DefaultLayout, LoginLayout, BlankLayout } from "./layouts";

// Route Views
import Dashboard from "./views/Dashboard/index";
import Login from "./views/Login/index";
import Clients from "./views/Clients/index";
import EditClient from "./views/EditClient/index";
import EditUser from "./views/EditUser/index";
import Users from "./views/Users/index";
import Locations from "./views/Locations/index";
import EditLocation from "./views/EditLocation/index";
import Feedback from "./views/Feedback/index";
import Waypoints from "./views/Waypoints/index";
import QRCodes from "./views/QRCodes";


export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Navigate to="/dashboard" />
  },
  {
    path: "/login",
    exact: true,
    layout: LoginLayout,
    component: Login
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard
  },
  {
    path: "/clients",
    layout: DefaultLayout,
    component: Clients
  },
  {
    path: "/add-client",
    layout: DefaultLayout,
    component: EditClient
  },
  {
    path: "/edit-client/:clientId",
    layout: DefaultLayout,
    component: EditClient
  },
  {
    path: "/users",
    layout: DefaultLayout,
    component: Users
  },
  {
    path: "/add-user",
    layout: DefaultLayout,
    component: EditUser
  },
  {
    path: "/edit-user/:userId",
    layout: DefaultLayout,
    component: EditUser
  },
  {
    path: "/locations",
    layout: DefaultLayout,
    component: Locations
  },
  {
    path: "/add-location",
    layout: DefaultLayout,
    component: EditLocation
  },
  {
    path: "/edit-location/:locationId",
    layout: DefaultLayout,
    component: EditLocation
  },
  {
    path: "/feedback",
    layout: DefaultLayout,
    component: Feedback
  },
  {
    path: "/waypoints/:locationId",
    layout: DefaultLayout,
    component: Waypoints
  },
  {
    path: "/qrcodes/:locationId",
    layout: BlankLayout,
    component: QRCodes
  },
];
