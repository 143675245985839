import firebase from 'firebase';
import * as actionConstants from '../actionConstants';
import { generateLocationCode, generateWaypointCode } from '../utils/tools';

export const clearAddedLocation = () => dispatch => {
  dispatch({type: actionConstants.CLEAR_ADDED_LOCATION})
}

export const clearLocationState = () => dispatch => {
  dispatch({type: actionConstants.CLEAR_LOCATION_STATE})
}

export const getLocations = () => async (dispatch, getState) => {
  dispatch({ type: actionConstants.LOADING_LOCATIONS });
  let currentUser = await firebase.auth().currentUser;

  if (getState().user.user.type === 'super') {
    return firebase
    .firestore()
    .collectionGroup('locations')
    .get()
    .then(querySnapshot => {
      let locations = [];
      querySnapshot.forEach(doc => {
        locations.push({id: doc.id, client: doc.parent.parent.id, ...doc.data()})
      })
      dispatch({ type: actionConstants.LOADED_LOCATIONS, payload: locations})
    })
    .catch(error => console.log(error))
  } else {
    return firebase
      .firestore()
      .collection('clients')
      .doc(getState().client.clients[0].id)
      .collection('locations')
      .get()
      .then(querySnapshot => {
        let locations = [];
        querySnapshot.forEach(doc => {
          locations.push({id: doc.id, client: getState().client.clients[0].id, ...doc.data()})
        })
        dispatch({ type: actionConstants.LOADED_LOCATIONS, payload: locations})
      })
      .catch(error => console.log(error))
  }
} 

export const getLiveLocations = () => async (dispatch, getState) => {
  dispatch({ type: actionConstants.LOADING_LOCATIONS });
  let currentUser = await firebase.auth().currentUser;

  if (getState().user.user.type === 'super') {
    return firebase
    .firestore()
    .collectionGroup('locations')
    .onSnapshot(querySnapshot => {
      let locations = [];
      querySnapshot.forEach(doc => {
        locations.push({id: doc.id, client: doc.ref.parent.parent.id, ...doc.data()})
      })
      dispatch({ type: actionConstants.LOADED_LOCATIONS, payload: locations})
    })
  } else {
    return firebase
      .firestore()
      .collection('clients')
      .doc(getState().client.clients[0].id)
      .collection('locations')
      .onSnapshot(querySnapshot => {
        let locations = [];
        querySnapshot.forEach(doc => {
          locations.push({id: doc.id, client: getState().client.clients[0].id, ...doc.data()})
        })
        dispatch({ type: actionConstants.LOADED_LOCATIONS, payload: locations})
      })
  }
} 

/**
 * Add a location
 *
 * @param data
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
 export const addLocation = (data, callback) => {
  return (dispatch, getState) => {
    dispatch({type: actionConstants.ADDING_LOCATION});
    const locationData = {
      name: data.name,
      client: data.client,
      logo: data.logo,
      primaryColor: data.primaryColor,
      active: data.active,
      locationCode: generateLocationCode(),
      logoOnRoute: data.logoOnRoute,
      logoOnDestinations: data.logoOnDestinations,
    };
    return firebase
      .firestore()
      .collection('clients')
      .doc(data.client)
      .collection('locations')
      .add(locationData)
      .then(doc => {
        dispatch({
          type: actionConstants.ADDED_LOCATION,
          payload: {id: doc.id, user: getState().user.user.id, ...locationData},
        });
        callback && callback({id: doc.id, user: getState().user.user.id, ...locationData})
      });
  };
};

/**
 * Edit a location
 *
 * @param data
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
export const editLocation = (location, data, callback) => {
  return (dispatch, getState) => {
    dispatch({type: actionConstants.UPDATING_LOCATION});
    const locationData = {
      name: data.name,
      client: data.client,
      logo: data.logo,
      primaryColor: data.primaryColor,
      active: data.active,
      logoOnRoute: data.logoOnRoute,
      logoOnDestinations: data.logoOnDestinations,
    };
    return firebase
      .firestore()
      .collection('clients')
      .doc(data.client)
      .collection('locations')
      .doc(location.id)
      .update(locationData)
      .then(doc => {
        dispatch({
          type: actionConstants.UPDATED_LOCATION,
          payload: {id: location.id, user: getState().user.user.id, ...locationData},
        });
        callback && callback({id: location.id, user: getState().user.user.id, ...locationData})
      });
  };
};

export const getWaypoints = () => async (dispatch, getState) => {
  dispatch({ type: actionConstants.LOADING_WAYPOINTS });
  
  if (getState().user.user.type === 'super') {
    return firebase
    .firestore()
    .collectionGroup('waypoints')
    .get()
    .then(querySnapshot => {
      let waypoints = [];
      querySnapshot.forEach(doc => {
        waypoints.push({id: doc.id, ...doc.data()})
      })
      dispatch({ type: actionConstants.LOADED_WAYPOINTS, payload: waypoints})
    })
    .catch(error => console.log(error))
  } else {
    return firebase
      .firestore()
      .collectionGroup('waypoints')
      .get()
      .then(querySnapshot => {
        let waypoints = [];
        querySnapshot.forEach(doc => {
          if (doc.exists && doc.ref.parent.parent.parent.parent.id === getState().client.clients[0].id) {
            waypoints.push({id: doc.id, ...doc.data()})
          }
        })
        dispatch({ type: actionConstants.LOADED_WAYPOINTS, payload: waypoints})
      })
      .catch(error => console.log(error))
  }
} 

export const getLiveWaypoints = (location) => async (dispatch, getState) => {
  dispatch({ type: actionConstants.LOADING_LIVE_WAYPOINTS });
  let currentUser = await firebase.auth().currentUser;

  return firebase
    .firestore()
    .collection('clients')
    .doc(location.client)
    .collection('locations')
    .doc(location.id)
    .collection('waypoints')
    .onSnapshot(querySnapshot => {
      let waypoints = [];
      querySnapshot.forEach(doc => {
        waypoints.push({id: doc.id, ...doc.data()})
      })
      dispatch({ type: actionConstants.LOADED_LIVE_WAYPOINTS, payload: waypoints})
    })
}

export const generateWaypoints = location => async (dispatch, getState) => {
  dispatch({ type: actionConstants.GENERATING_WAYPOINTS });

  for (let i = 1; i <= 50; i++) {
    await firebase
    .firestore()
    .collection('clients')
    .doc(location.client)
    .collection('locations')
    .doc(location.id)
    .collection('waypoints')
    .add({
      name: '',
      category: '',
      searchable: false,
      waypointCode: generateWaypointCode()
    })
    .then(doc => doc.id)
  }
  dispatch({ type: actionConstants.GENERATED_WAYPOINTS });

}

export const copyLocation = (from, to) => async (dispatch) => {

  // const locationData = {
  //   name: name,
  //   client: from.client,
  //   logo: from.logo,
  //   primaryColor: from.primaryColor,
  //   active: from.active,
  //   locationCode: generateLocationCode(),
  //   logoOnRoute: from.logoOnRoute,
  //   logoOnDestinations: from.logoOnDestinations,
  // };

  // let to = await firebase
  //   .firestore()
  //   .collection('clients')
  //   .doc(from.client)
  //   .collection('locations')
  //   .add(locationData)
  //   .then(doc => {
  //     return doc.id
  //   });

  let waypoints = await firebase
    .firestore()
    .collection('clients')
    .doc(from.client)
    .collection('locations')
    .doc(from.id)
    .collection('waypoints')
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        firebase
        .firestore()
        .collection('clients')
        .doc(from.client)
        .collection('locations')
        .doc('And0tNMeEXwt6By0POvG')
        .collection('waypoints')
        .doc(doc.id)
        .set({...doc.data()})
      })
    });
  
  let connections = await firebase
    .firestore()
    .collection('clients')
    .doc(from.client)
    .collection('locations')
    .doc(from.id)
    .collection('connections')
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        firebase
        .firestore()
        .collection('clients')
        .doc(from.client)
        .collection('locations')
        .doc('And0tNMeEXwt6By0POvG')
        .collection('connections')
        .doc(doc.id)
        .set({...doc.data()})
      })
    });    
}

export const deleteLocation = (from) => async (dispatch) => {
  firebase
    .firestore()
    .collection('clients')
    .doc(from.client)
    .collection('locations')
    .where('name', '==', 'Packers')
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.delete()
      })
    })
}