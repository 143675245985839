/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import {
  Alert,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  FormCheckbox,
  FormSelect,
  ListGroup,
  ListGroupItem,
  Col,
  Form,
  FormFeedback,
  Button,
  FormInput,
  FormTextarea,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
import {useParams} from "react-router-dom";
import { getUserLocale } from 'get-user-locale';
//import 'react-flags-select/css/react-flags-select.css';
import PageTitle from "../../components/common/PageTitle";
import LogoComponent from "../../components/user-profile/LogoComponent";
import { accessCheck } from "../../utils/tools";

const language = (getUserLocale() && getUserLocale().length === 5) ? getUserLocale() : 'en_US';
const locale = language.substr(-2).toLowerCase();

const EditLocation = (props) => {
  let {locationId} = useParams();
  const [location, setLocation] = useState(props.locations.find(location => location.id === locationId));
  const [client, setClient] = useState(props.user.type === 'super' ? null : props.clients[0].id);
  const [name, setName] = useState('');
  const [active, setActive] = useState(true);
  const [logo, setLogo] = useState(null);
  const [primaryColor, setPrimaryColor] = useState(null);
  const [locations, setLocations] = useState(props.locations);
  const [validName, setValidName] = useState(null);
  const [addedLocation, setAddedLocation] = useState(false);
  const [logoOnRoute, setLogoOnRoute] = useState(false);
  const [logoOnDestinations, setLogoOnDestinations] = useState(false);
  const params = (new URL(document.location)).searchParams;
  const returnTo = params.get('return-to')

  useEffect(() => {
    props.getLiveLocations(props.user.id)
    //props.copyLocation(props.locations.find(location => location.id === locationId), props.locations.find(location => location.id === 'And0tNMeEXwt6By0POvG'));
    //props.deleteLocation(props.locations.find(location => location.id === locationId));
    !accessCheck(props.user, 'locations') && (window.location.href = '/') 
  }, [])

  useEffect(() => {
    if (location) {
      setName(location.name || '')
      setClient(location.client || null)
      setLogo(location.logo || null)
      setActive(!!location.active)
      setPrimaryColor(location.primaryColor || null)
      setValidName(location.name.length > 2)
      setLogoOnRoute(!!location.logoOnRoute)
      setLogoOnDestinations(!!location.logoOnDestinations)
    }
  }, [location])

  useEffect(() => {
    if (locationId) {
      setLocation(props.locations.find(location => location.id === locationId))
    }
  }, [props.locations, locationId])

  useEffect(() => {
    setLocations(props.locations)
  }, [props.locations])

  return (
    <Container fluid className="main-content-container px-0">
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        {!props.removeTitles && (
          <Row noGutters className="page-header py-4">
            <Col xs="12" sm="8">
              <PageTitle sm="12" title={(locationId ? 'Edit' : 'Add') + ' Location'} subtitle={'Locations'} className="text-sm-left" />
            </Col>
          </Row>
        )}
        {/* First Row of Posts */}
        <Row>
          <Col className="mb-4">
            <Card className={'mb-0'}>
              <Container fluid>
                <Row>
                  <Col lg={6}>
                    <ListGroup flush>
                      <ListGroupItem className="p-3">
                        <Row>
                          <Col>
                            <Form>
                              <Row form>
                                <Col md="12" className="form-group">
                                  <label htmlFor="feName">Name</label>
                                  <FormInput
                                    id='feName'
                                    onChange={event => setName(event.target.value)}
                                    placeholder='Name'
                                    value={name}
                                    invalid={validName === false}
                                    onBlur={() => setValidName(name.length > 2)}
                                  />
                                  <FormFeedback>Please enter a valid name.</FormFeedback>
                                </Col>
                              </Row>               
                            </Form>
                          </Col>
                        </Row>
                        {props.user.type === 'super' && (
                          <Row>
                            <Col>
                              <Form>
                                <Row form>
                                  <Col md="12" className="form-group">
                                    <label htmlFor="feClient">Client</label>
                                    <FormSelect id="feClient" value={client} onChange={event => setClient(event.target.value)}>
                                    <option value={null}>Select</option>
                                      {props.clients.filter(client => ((!location || !location.id) && client.active) || (location && location.id && client)).map(client => (
                                        <option value={client.id}>{client.name}</option>
                                      ))}
                                    </FormSelect>
                                  </Col>
                                </Row>               
                              </Form>
                            </Col>
                          </Row>
                        )}
                        <LogoComponent data={location} setDataLogo={setLogo} labelAddition={' (defaults to client logo if none entered)'} />
                        <Row style={{marginTop: 24}}>
                          <Col>
                            <div>
                              <label>
                                Select your primary color (defaults to client primary color if none entered)
                              </label>
                              <Row className={'mb-3'} style={{marginTop: 8}}>
                                <div className={'col-3'}>
                                  <div onClick={() => setPrimaryColor('#000000')} style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#000000', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#000000' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                                <div onClick={() => setPrimaryColor('#727679')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#727679', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#727679' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                                <div onClick={() => setPrimaryColor('#C5AB5E')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#C5AB5E', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#C5AB5E' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                                <div onClick={() => setPrimaryColor('#C23742')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#C23742', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#C23742' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                              </Row>
                              <Row className={'mb-3'}>
                                <div onClick={() => setPrimaryColor('#458102')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#458102', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#458102' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                                <div onClick={() => setPrimaryColor('#0A2345')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#0A2345', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#0A2345' && (
                                      <i className={'fa fa-check text-center'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                                <div onClick={() => setPrimaryColor('#63285B')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#63285B', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#63285B' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                                <div onClick={() => setPrimaryColor('#2D79CF')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#2D79CF', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#2D79CF' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                              </Row>
                              <Row className={'mb-3'}>
                                <div onClick={() => setPrimaryColor('#CB5799')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#CB5799', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#CB5799' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                                <div onClick={() => setPrimaryColor('#E05A2B')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#E05A2B', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#E05A2B' && (
                                      <i className={'fa fa-check text-center'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                                <div onClick={() => setPrimaryColor('#804C9E')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#804C9E', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#804C9E' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                                <div onClick={() => setPrimaryColor('#872764')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#872764', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#872764' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                        <Row style={{marginTop: 16, marginBottom: 16}}>
                          <Col>
                            <Form>
                              <Row form>
                                <Col md="12" className="form-group">
                                  <label htmlFor="feName">Display logo on route screens</label>
                                  <FormCheckbox
                                      toggle
                                      checked={logoOnRoute}
                                      onChange={() => setLogoOnRoute(!logoOnRoute)}
                                    >
                                  </FormCheckbox>
                                </Col>
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                        <Row style={{marginTop: 16, marginBottom: 16}}>
                          <Col>
                            <Form>
                              <Row form>
                                <Col md="12" className="form-group">
                                  <label htmlFor="feName">Display destination logos when available</label>
                                  <FormCheckbox
                                      toggle
                                      checked={logoOnDestinations}
                                      onChange={() => setLogoOnDestinations(!logoOnDestinations)}
                                    >
                                  </FormCheckbox>
                                </Col>
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                        <Row style={{marginTop: 16, marginBottom: 16}}>
                          <Col>
                            <Form>
                              <Row form>
                                <Col md="12" className="form-group">
                                  <label htmlFor="feName">Active</label>
                                  <FormCheckbox
                                      toggle
                                      checked={active}
                                      onChange={() => setActive(!active)}
                                    >
                                  </FormCheckbox>
                                </Col>
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form>
                              <Row form>
                                <Col md="12" className="form-group">
                                <Button
                                  className="mr-1"
                                  theme="secondary"
                                  onClick={() => props.onCancel ? props.onCancel() : window.location.href='/locations'}
                                >Cancel</Button>
                                <Button
                                  theme="accent"
                                  disabled={!validName}
                                  onClick={() => {
                                    if (!!location) {
                                      props.editLocation(location, {name, client, primaryColor, logo, active, logoOnRoute, logoOnDestinations}, () => window.location.href='/locations')
                                    } else {
                                      props.addLocation({name, client, primaryColor, logo, active, logoOnRoute, logoOnDestinations}, () => window.location.href='/locations')
                                    }
                                  }}
                                >Save</Button>
                                </Col>
                              </Row>                        
                            </Form>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                  <Col lg="6"></Col>
                </Row>
              </Container>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default EditLocation;
