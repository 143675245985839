export const LOGGING_IN = 'LOGGING_IN';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOADING_USER = 'LOADING_USER';
export const LOADED_USER = 'LOADED_USER';
export const LOADING_USERS = 'LOADING_USERS';
export const LOADED_USERS = 'LOADED_USERS';
export const UPDATING_USER = 'UPDATING_USER';
export const UPDATED_USER = 'UPDATED_USER';
export const ADDING_USER = 'ADDING_USER';
export const ADDED_USER = 'ADDED_USER';
export const CLEAR_ADDED_USER = 'CLEAR_ADDED_USER';

export const GETTING_VISITOR_COUNT = 'GETTING_VISITOR_COUNT';
export const GOT_VISITOR_COUNT = 'GOT_VISITOR_COUNT';
export const GOT_REAL_TIME_COUNT = 'GOT_REAL_TIME_COUNT';
export const GOT_TOTAL_VISITS_COUNT = 'GOT_TOTAL_VISITS_COUNT';
export const GOT_CHOSEN_DESTINATIONS = 'GOT_CHOSEN_DESTINATIONS';

export const LOADING_CLIENTS = 'LOADING_CLIENTS';
export const LOADED_CLIENTS = 'LOADED_CLIENTS';
export const ADDING_CLIENT = 'ADDING_CLIENT';
export const ADDED_CLIENT = 'ADDED_CLIENT';
export const UPDATING_CLIENT = 'UPDATING_CLIENT';
export const UPDATED_CLIENT = 'UPDATED_CLIENT';
export const CLEAR_ADDED_CLIENT = 'CLEAR_ADDED_CLIENT';

export const LOADING_LOCATIONS = 'LOADING_LOCATIONS';
export const LOADED_LOCATIONS = 'LOADED_LOCATIONS';
export const ADDING_LOCATION = 'ADDING_LOCATION';
export const ADDED_LOCATION = 'ADDED_LOCATION';
export const UPDATING_LOCATION = 'UPDATING_LOCATION';
export const UPDATED_LOCATION = 'UPDATED_LOCATION';
export const CLEAR_ADDED_LOCATION = 'CLEAR_ADDED_LOCATION';
export const CLEAR_LOCATION_STATE = 'CLEAR_LOCATION_STATE';
export const LOADING_WAYPOINTS = 'LOADING_WAYPOINTS';
export const LOADED_WAYPOINTS = 'LOADED_WAYPOINTS';
export const LOADING_LIVE_WAYPOINTS = 'LOADING_LIVE_WAYPOINTS';
export const LOADED_LIVE_WAYPOINTS = 'LOADED_LIVE_WAYPOINTS';
export const GENERATING_WAYPOINTS = 'GENERATING_WAYPOINTS';
export const GENERATED_WAYPOINTS = 'GENERATED_WAYPOINTS';
