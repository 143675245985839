import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, CardHeader, Card, CardBody, FormSelect } from "shards-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile, faMeh, faFrown } from '@fortawesome/free-solid-svg-icons';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { accessCheck } from "../../utils/tools";

import PageTitle from "../../components/common/PageTitle";
import { getVisitors } from "../../actions/dashboardActions";
import SmallStats from "../../components/common/SmallStats";

const moment = require('moment');

const Feedback = (props) => {

  const [averageFeedback, setAverageFeedback] = useState([])
  const [selectedMonth, setSelectedMonth] = useState(moment().format('M'))
  const [location, setLocation] = useState('all')
  const [locations, setLocations] = useState(props.locations)
  const [client, setClient] = useState(props.user.type === 'super' ? 'all' : props.clients[0].id)
  const [averageScore, setAverageScore] = useState({})
  const [lowestAverageScore, setLowestAverageScore] = useState({})
  const [highestAverageScore, setHighestAverageScore] = useState({})

  const chartOptions = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
      },
      y: {
        min: 0,
        position: 'right'
      }
    },
    
  }

  useEffect(() => {
    document.title = 'Feedback: WaySnap';
    props.getWaypoints()
    !accessCheck(props.user, 'feedback') && (window.location.href = '/') 
  }, [])

  useEffect(() => {
    let { startDate, endDate } = getStartAndEndDate()
    props.getChosenRoutes(location !== 'all' ? location : null, client !== 'all' ? client : null, startDate.toISOString(), endDate.toISOString())
    if (client !== 'all') {
      setLocations(props.locations.filter(location => location.client === client))
    } else {
      setLocations(props.locations)
    }
  }, [location, client, selectedMonth])

  useEffect(() => {
    if (averageFeedback.length > 0) {
      getAverageScore()
      getLowestAverageScore()
      getHighestAverageScore()
    }
  }, [averageFeedback])

  useEffect(() => {
    setAverageFeedback(calculateAverageFeedback(props.chosenDestinations, props.locations, props.clients))
  }, [props.chosenDestinations, props.loadedWaypoints])

  const getStartAndEndDate = () => {
    let startDate = moment(moment().format('Y') + '-' + selectedMonth + '-01 00:00:00').startOf('month')
    let endDate = moment(moment().format('Y') + '-' + selectedMonth + '-01 00:00:00').endOf('month')
    return { startDate, endDate } 
  }

  const calculateAverageFeedback = (routes, locations, clients) => {
    let mostVisited = {};
    let result = [];
    if (routes && routes.length > 0) {
      routes.filter(route => route.feedback).map(route => {
        if (Object.keys(mostVisited).indexOf(route.to) !== -1) {
          mostVisited[route.to]['count'] = mostVisited[route.to]['count'] + 1
          mostVisited[route.to]['feedbackTotal'] = mostVisited[route.to]['feedbackTotal'] + route.feedback
        } else {
          mostVisited[route.to] = {}
          mostVisited[route.to]['count'] = 1;
          mostVisited[route.to]['client'] = route.client;
          mostVisited[route.to]['location'] = route.location;
          mostVisited[route.to]['feedbackTotal'] = route.feedback;
        }
      })
      result = Object.keys(mostVisited).map(key => ({
        name: props.waypoints.find(waypoint => waypoint.id === key).name,
        location: locations.find(location => location.id === mostVisited[key]['location']).name,
        client: clients.find(client => client.id === mostVisited[key]['client']).name,
        average: parseInt((mostVisited[key]['feedbackTotal'] / mostVisited[key]['count'])),
        count: parseInt(mostVisited[key]['count']),
        feedbackTotal: parseInt(mostVisited[key]['feedbackTotal']),
      }))
    }
    return result
  }

  const getAverageScore = () => {
    let total = 0;
    let count = 0;
    averageFeedback.map(destination => {
      total = total + destination.feedbackTotal
      count = count + destination.count
    })
    setAverageScore({ total, count })
  }

  const getLowestAverageScore = () => {
    let result = averageFeedback.sort((a, b) => a.average - b.average)[0]
    setLowestAverageScore(result)
  }

  const getHighestAverageScore = () => {
    let result = averageFeedback.sort((a, b) => b.average - a.average)[0]
    setHighestAverageScore(result)
  }

  const returnSmiley = (score) => {
    score = parseInt(score)
    switch (score) {
      case 5:
        return (
          <span style={{fontSize: 70, color: '#68D266'}}>
            <FontAwesomeIcon icon={faSmile} />
          </span>
        )
      case 4:
        return (
          <span style={{fontSize: 70, color: '#B2D267'}}>
            <FontAwesomeIcon icon={faSmile} />
          </span>
        )
      case 3:
        return (
          <span style={{fontSize: 70, color: '#FAC702'}}>
            <FontAwesomeIcon icon={faMeh} />
          </span>
        )
      case 2:
        return (
          <span style={{fontSize: 70, color: '#DF8A52'}}>
            <FontAwesomeIcon icon={faFrown} />
          </span>
        )
      case 1:
        return (
          <span style={{fontSize: 70, color: '#DF5364'}}>
            <FontAwesomeIcon icon={faFrown} />
          </span>
        )  
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle title="Feedback" className="text-sm-left mb-3" />
      </Row>

      <Row>
        <Col className="mb-4">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="12" sm="4">
                  <h6 className="m-0">Average destination score</h6>
                </Col>
                <Col xs="12" sm="8" className='text-right'>
                  <Row>
                    <Col xs="1"></Col>
                    <Col className='text-right pl-0 pr-1'>
                      <FormSelect value={selectedMonth} id="feMonth" onChange={
                        event => { 
                          setSelectedMonth(event.target.value);
                        }}>
                        <option value={1}>January</option>
                        <option value={2}>February</option>
                        <option value={3}>March</option>
                        <option value={4}>April</option>
                        <option value={5}>May</option>
                        <option value={6}>June</option>
                        <option value={7}>July</option>
                        <option value={8}>August</option>
                        <option value={9}>September</option>
                        <option value={10}>October</option>
                        <option value={11}>November</option>
                        <option value={12}>December</option>
                      </FormSelect>
                    </Col>
                    <Col className='text-right pl-0 pr-1'>
                      {props.user.type === 'super' && (
                        <FormSelect value={client} id="feClients" onChange={event => setClient(event.target.value)}>
                          <option value={'all'}>All clients</option>
                          {props.clients.map(client => (
                            <option value={client.id}>{client.name}</option>
                          ))}
                        </FormSelect>
                      )}
                    </Col>
                    <Col className='text-right pl-0 pr-0'>
                      <FormSelect value={location} id="feLocations" onChange={event => setLocation(event.target.value)}>
                        <option value={'all'}>All locations</option>
                        {locations.map(location => (
                          <option value={location.id}>{location.name}</option>
                        ))}
                      </FormSelect>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Chart
                type='line'
                datasetIdKey='id'
                options={chartOptions}
                data={{
                  labels: averageFeedback.map(destination => destination.name),
                  datasets: [
                    {
                      id: 1,
                      label: '',
                      data: averageFeedback.map(destination => destination.average),
                      backgroundColor: "rgba(23,198,113,0.1)",
                      borderWidth: 1.5,
                      borderColor: "rgb(23,198,113)",
                      gridLines: false,
                      fill: true
                    },
                  ],
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {averageFeedback && averageFeedback.length > 0 && (
        <Row>
          <Col className="col-lg mb-4" md="12" sm="12">
            <Card>
              <CardHeader style={{borderBottom: '1px solid #EEEEEE'}}>
                <h6 className="m-0">Average feedback score</h6>
                <h8 className="m-0"><span style={{fontSize: 12}}>All locations</span></h8>
              </CardHeader>
              <CardBody style={{paddingTop: 0, paddingBottom: 0}}>
                <Row>
                  <Col>{returnSmiley(parseInt(averageScore.total / averageScore.count))}</Col>
                  <Col style={{textAlign: 'right', paddingTop: 16}}>
                    <span style={{fontSize: 30}}>{parseInt(averageScore.total / averageScore.count)}</span><br />
                    <span style={{fontSize: 11}}>{parseInt(averageScore.count)} RESPONSES</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className="col-lg mb-4" md="12" sm="12">
            <Card>
              <CardHeader style={{borderBottom: '1px solid #EEEEEE'}}>
                <h6 className="m-0">Lowest feedback score</h6>
                <h8 className="m-0"><span style={{fontSize: 12}}>{lowestAverageScore.name}</span></h8>
              </CardHeader>
              <CardBody style={{paddingTop: 0, paddingBottom: 0}}>
                <Row>
                  <Col>{returnSmiley(lowestAverageScore.average)}</Col>
                  <Col style={{textAlign: 'right', paddingTop: 16}}>
                    <span style={{fontSize: 30}}>{parseInt(lowestAverageScore.average)}</span><br />
                    <span style={{fontSize: 11}}>{parseInt(lowestAverageScore.count)} RESPONSES</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className="col-lg mb-4" md="12" sm="12">
            <Card>
              <CardHeader style={{borderBottom: '1px solid #EEEEEE'}}>
                <h6 className="m-0">Highest feedback score</h6>
                <h8 className="m-0"><span style={{fontSize: 12}}>{highestAverageScore.name}</span></h8>
              </CardHeader>
              <CardBody style={{paddingTop: 0, paddingBottom: 0}}>
                <Row>
                  <Col>{returnSmiley(highestAverageScore.average)}</Col>
                  <Col style={{textAlign: 'right', paddingTop: 16}}>
                    <span style={{fontSize: 30}}>{parseInt(highestAverageScore.average)}</span><br />
                    <span style={{fontSize: 11}}>{parseInt(highestAverageScore.count)} RESPONSES</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <Row>
                <Col xs="12" sm="4">
                  <h6 className="m-0">Feedback comments</h6>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="p-0">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      Destination name
                    </th>
                    <th scope="col" className="border-0">
                      Location
                    </th>
                    {props.user.type === 'super' && (
                      <th scope="col" className="border-0 d-none d-lg-table-cell">
                        Client
                      </th>
                    )}
                    <th scope="col" className="border-0">
                      Score
                    </th>
                    <th scope="col" className="border-0 d-none d-lg-table-cell">
                      Date
                    </th>
                    <th scope="col" className="border-0">
                      Comment
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.chosenDestinations && props.chosenDestinations.length > 0 && props.chosenDestinations.filter(destination => destination.comment).map(destination => {
                    return (
                      <tr key={destination.name}>
                        <td>
                          {props.waypoints.find(waypoint => waypoint.id === destination.to).name}
                        </td>
                        <td>
                          {!!locations.find(location => location.id === destination.location) && locations.find(location => location.id === destination.location).name}
                        </td>
                        {props.user.type === 'super' && (
                          <td className="d-none d-lg-table-cell">
                            {props.clients.find(client => client.id === destination.client).name}
                          </td>
                        )}
                        <td>
                          {destination.feedback}
                        </td>
                        <td className="d-none d-lg-table-cell">
                          {moment(destination.time).format('M-D-Y')}
                        </td>
                        <td>
                          {destination.comment}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
};

export default Feedback;
