/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormSelect,
} from "shards-react";
import { accessCheck } from "../../utils/tools";

import PageTitle from "../../components/common/PageTitle";

const Waypoints = (props) => {

  let {locationId} = useParams();
  const [waypoints, setWaypoints] = useState([]);
  const [location] = useState(props.locations.find(location => location.id === locationId))

  useEffect(() => {
    props.getLiveWaypoints(location)
    document.title = 'Nodes: WaySnap admin';
    !accessCheck(props.user, 'waypoints') && (window.location.href = '/') 
  }, [])

  useEffect(() => {
    setWaypoints(props.locationWaypoints)
  }, [props.locationWaypoints])

  return (
    <Container fluid className="main-content-container px-0">
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}

        {/* First Row of Posts */}
        <Row>
          <Col>
            <Card small className="mb-4 mt-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col xs="12" sm="4">
                    <h6 className="m-0">{location.name} Nodes</h6>
                  </Col>
                  <Col xs="12" sm="8" className='text-right'>
                    <Row>
                      <Col xs="6"></Col>
                      <Col className='text-right pl-0' xs="3">
                        {props.generatingWaypoints ? (
                          <span>Generating...</span>  
                        ) : (
                          <Button
                            theme="accent"
                            onClick={() => props.generateWaypoints(location)}
                          >Generate 50 nodes</Button>
                        )}
                        
                      </Col>
                      <Col className='text-right pl-0' xs="3">
                        <Button
                          theme="accent"
                          onClick={() => window.open('/qrcodes/' + location.id)}
                        >Output QR codes</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        Name
                      </th>
                      <th scope="col" className="border-0">
                        Code
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {waypoints && waypoints.length > 0 && waypoints.map(waypoint => {
                      return (
                        <tr key={waypoint.id}>
                          <td>
                            {waypoint.name}
                          </td>
                          <td>
                            {waypoint.waypointCode}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Waypoints;
