/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { Component } from "react";
import firebase from "firebase";
import { getUserLocale } from 'get-user-locale';
import {
  Container,
  Row,
  Col,
  Card,
  Button, Form, FormInput, FormGroup, FormFeedback
} from "shards-react";
import { WEBSITE_URL } from "../../environmentVariables";

const language = (getUserLocale() && getUserLocale().length === 5) ? getUserLocale() : 'en_US';
const locale = language.substr(-2).toLowerCase();
class Login extends Component {
  constructor(props) {
    super(props)
    props.logout()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    console.log(nextProps)
    if (nextProps.user
      && nextProps.user.id
      && nextProps.locations
      && nextProps.loadedLocations
      && nextProps.clients
      && nextProps.clients.length > 0
      && nextProps.users
      && nextProps.users.length > 0
    ) {
      window.location.href = '/';
    }
    if (nextProps.user && nextProps.user.id && this.props.user !== nextProps.user) {
      nextProps.getLiveUsers(nextProps.user.id)
      nextProps.getLiveClients(nextProps.user.id)
    }
    if (nextProps.clients && nextProps.clients.length !== this.props.clients.length && nextProps.clients.length > 0) {
      nextProps.getLiveLocations(nextProps.user.id)
      nextProps.getWaypoints(nextProps.user.id)
    }
    if (nextProps.loginFail && nextProps.loginFail !== this.props.loginFail) {
      this.setState({loggingIn: false})
    }
  }

  state = {
    email: null,
    password: null,
    loggingIn: false,
  };

  render() {
    return (
      <Container fluid className="px-0" style={{fontWeight: 200}}>
        <Row style={{marginTop: 130}}>
          <Col md="6" className="mx-auto">
            <Card small className="mb-4" style={{maxWidth: 400, marginLeft: 'auto', marginRight: 'auto', paddingLeft: 18, paddingRight: 18, paddingBottom: 32}}>
              <Container>
                <Row>
                  <Col className={'text-center'} style={{paddingTop: 60, paddingBottom: 16}}>
                    <img 
                      src={require('../../images/waysnap-logo.png')}
                      style={{ width: 200 }}  
                    />
                  </Col>
                </Row>
                <Row>
                  <Col style={{paddingTop: 8, paddingBottom: 8}}>
                    <span style={{fontSize: 18, fontWeight: 500}}>Sign in</span><br />
                    <span style={{fontSize: 14, color: '#AAAAAA'}}>We're happy to have you here again!</span>
                  </Col>
                </Row>
                <Row>
                  <Col style={{paddingTop: 8, paddingBottom: 8}}>
                    <Form>
                      <FormGroup>
                        <label htmlFor="#email" style={{fontSize: 10}}>EMAIL ADDRESS</label>
                        <FormInput id="#email" placeholder="Email address" onChange={event => this.setState({email: event.target.value})}/>
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="#password" style={{fontSize: 10}}>PASSWORD</label>
                        <FormInput
                          id="#password"
                          placeholder="Password"
                          type={'password'}
                          onChange={event => this.setState({password: event.target.value})}
                          invalid={this.props.loginFail}
                        />
                        <FormFeedback>User cannot be found. Please try again</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        {!this.state.loggingIn ? (
                          <Button
                            style={{width: '100%'}}
                            onClick={() => {
                              this.props.authenticateWithEmailAddressAndPassword(this.state.email, this.state.password);
                              this.setState({loggingIn: true}, () => setTimeout(() => {
                                this.props.getLiveLocations();
                                this.props.getWaypoints();
                              }, 3000))
                            }}
                            disabled={!this.state.email || !this.state.password}
                          >Sign in</Button>
                        ) : (
                          'Loading...'
                        )}
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center" style={{paddingTop: 8, paddingBottom: 8}}>
                    <span style={{fontSize: 12}}>Don't have a WaySnap account yet? <a href="/sign-up" style={{fontWeight: 300}}>Sign up</a></span><br />
                    <span style={{fontSize: 12}}>Forget your password? <a href="/forgot-password" style={{fontWeight: 300}}>Get a new password</a></span>
                  </Col>
                </Row>
              </Container>
            </Card>
          </Col>
        </Row>
        <Row>
          <Container style={{maxWidth: 400, fontSize: 11, fontWeight: 300}}>
            <Row>
              <Col className="text-right" style={{paddingLeft: 4, paddingRight: 4}}><a href={WEBSITE_URL + "/terms"} target="_blank">Terms of use</a></Col>
              <Col className="text-center" style={{paddingLeft: 4, paddingRight: 0}}><a href={WEBSITE_URL + "/privacy"} target="_blank">Privacy policy</a></Col>
              <Col className="text-left" style={{paddingLeft: 0, paddingRight: 4}}><a href={WEBSITE_URL + "/terms"} target="_blank">Website</a></Col>
            </Row>
            <Row style={{marginTop: 12}}>
              <Col className="text-center">&copy; 2022 Mirashade LLC</Col>
            </Row>
          </Container>
        </Row>
      </Container>
    );
  }
}

export default Login;
