import * as userActions from './userActions';
import * as loginActions from './loginActions';
import * as clientActions from './clientActions';
import * as locationActions from './locationActions';
import * as dashboardActions from './dashboardActions';

export const ActionCreators = {
  ...userActions,
  ...loginActions,
  ...clientActions,
  ...locationActions,
  ...dashboardActions,
};
