import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import SidebarNavItemOpener from "./SidebarNavItemOpener";
import SidebarNavSubItem from "./SidebarNavSubItem";

import { accessCheck } from "../../../utils/tools";

const sidebarNavItems = [
  // {
  //   title: "Add New Post",
  //   htmlBefore: '<i class="material-icons">note_add</i>',
  //   to: "/add-new-post",
  // },
  {
    title: "Forms & Components",
    htmlBefore: '<i class="material-icons">view_module</i>',
    to: "/components-overview",
  },
  {
    title: "Tables",
    htmlBefore: '<i class="material-icons">table_chart</i>',
    to: "/tables",
  },
  // {
  //   title: "Errors",
  //   htmlBefore: '<i class="material-icons">error</i>',
  //   to: "/errors",
  // },
];

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)
    const isSettingsSubPage = window.location.href.indexOf('showing-defaults') !== -1 || window.location.href.indexOf('calendar-link-settings') !== -1
    this.state = {
      navItems: sidebarNavItems,
      showSettingsSubItems: isSettingsSubPage,
    };
  }

  render() {
    const { navItems: items } = this.state;
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {accessCheck(this.props.user, 'dashboard') && (
            <SidebarNavItem
              item={{
                title: "Dashboard",
                //htmlBefore: '<i class="material-icons">home</i>',
                to: "/",
              }}
            />
          )}
          {accessCheck(this.props.user, 'feedback') && (
            <SidebarNavItem
              item={{
                title: "Feedback",
                //htmlBefore: '<i class="material-icons">home</i>',
                to: "/feedback",
              }}
            />
          )}
          {accessCheck(this.props.user, 'users') && (
            <SidebarNavItem
              item={{
                title: "Users",
                //htmlBefore: '<i class="material-icons">calendar_month</i>',
                to: "/users",
              }}
            />
          )}
          {accessCheck(this.props.user, 'locations') && (
            <SidebarNavItem
              item={{
                title: "Locations",
                //htmlBefore: '<i class="material-icons">calendar_month</i>',
                to: "/locations",
              }}
            />
          )}
          {accessCheck(this.props.user, 'clients') && (
            <SidebarNavItem
              item={{
                title: "Clients",
                //htmlBefore: '<i class="material-icons">calendar_month</i>',
                to: "/clients",
              }}
            />
          )}
          {accessCheck(this.props.user, 'support') && (
            <SidebarNavItem
              item={{
                title: "Support tools",
                //htmlBefore: '<i class="material-icons">error</i>',
                to: "/support",
              }}
            />
          )}  
        </Nav>
      </div>
    )
  }
}

export default SidebarNavItems;
