const getDomain = () => {
  const full = window.location.href;
  if (full.indexOf('localhost')) {
    return full
  }
  const fullArray = full.split('.')
  return fullArray[1] + '.' + fullArray[2].substring(0, 3)
}

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyA91I_nBGYj1j4Ca9pSCOyKTTsyR4kqzDg",
  authDomain: "waysnap-de599.firebaseapp.com",
  projectId: "waysnap-de599",
  storageBucket: "waysnap-de599.appspot.com",
  messagingSenderId: "1018215384047",
  appId: "1:1018215384047:web:a73872f5a3f4ac253bec1a",
  measurementId: "G-BY2BRMYM87",
  databaseURL: "https://waysnap-de599.firebaseio.com",
};
export const WEB_NAV_URL = 'https://client.' + getDomain();
export const WEB_ADMIN_URL = 'https://admin.' + getDomain();
export const WEBSITE_URL = 'https://www.' + getDomain();